import validatorBuilder from 'validator-builder';

let chantierEnteteValidator = null;

export default class ChantierEntete {
	constructor(data = {}) {
		this.id = data.id;
		this.code = data.code;
		this.libelle = data.libelle;
		this.dateDebut = data.dateDebut;
		this.dateFin = data.dateFin;
		this.adresse = data.adresse;
		this.codePostal = data.codePostal;
		this.ville = data.ville;
		this.telephone = data.telephone;
		this.fax = data.fax;
		this.referenceChantierClient = data.referenceChantierClient;
		this.commentaireLivraison = data.commentaireLivraison;
		this.etat = data.etat;
		this.typeId = data.typeId;
		this.nomType = data.nomType;
		this.idPays = data.idPays;
		this.nomPays = data.nomPays;
		this.idSite = data.idSite;
		this.nomSite = data.nomSite;
		this.codeSite = data.codeSite;
		this.idSociete = data.idSociete;
		this.nomSociete = data.nomSociete;
		this.codeSociete = data.codeSociete;
		this.isClientDuBatiment = data.isClientDuBatiment;
		this.idLieu = data.idLieu;
		this.nomLieu = data.nomLieu;
		this.codeLieu = data.codeLieu;
		this.objetSiteClient = data.objetSiteClient;
		this.objetSociete = data.objetSociete || null;
		// si besoin, on indique une valeur par défaut
		// le (xxx || yyy) va être égal à yyy si xxx est undefined, null, false ou égal à 0
		this.codeExists = data.codeExists || null;
		//todo codeInvalid non utilise actuellement, a confirmer
		// this.codeInvalid = data.codeInvalid || null;
		this.estCodeAuto = data.estCodeAuto;
		this.isClientDisabled = data.isClientDisabled;
		this.niveauVisibilite = 0;
		this.idContact = data.idContact;
		this.nomContact = data.nomContact;
		this.isReferenceBasias = data.isReferenceBasias;
		this.isReferenceBasol = data.isReferenceBasol;
		this.isContamine = data.isContamine;
		this.isPotentielContamine = data.isPotentielContamine;
		this.chantierDuBatiment = data.chantierDuBatiment;
		this.isHeritageFromClient = data.isHeritageFromClient;
		this.isDuBatiment = data.isDuBatiment;
		this.isClientDuBatiment = data.isClientDuBatiment;
	}

	isValid() {
		chantierEnteteValidator = chantierEnteteValidator || validatorBuilder.getInstanceFor('ChantierEntete');
		const validationResults = chantierEnteteValidator.validate(this);
		return validationResults.isValid;
	}
}
