export default class DapAttenteConverterController {
    /* @ngInject */
    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        DAPsCommunicationService,
        DAPsService,
        DocumentsService,
        notification,
        $location,
        $anchorScroll,
        $translate,
        $uibModal,
        ModalService
    ){
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.DAPsCommunicationService = DAPsCommunicationService;
        this.DAPsService = DAPsService;
        this.DocumentsService = DocumentsService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$translate = $translate;
        this.$uibModal = $uibModal;
        this.ModalService = ModalService;
        this.currDap = null;
        this.loading = false;
        this.isOpenForm = true;
    }

    async $onInit()
    {
        this.currDap = await this.DAPsService.GetDapAttenteById(this.$stateParams.id);
        console.log("🚀 ~ DapAttenteConverterController ~ this.currDap:", this.currDap);
        
        const ids = this.currDap.transporteur.map(x => x.id);

        const tps = await this.DAPsService.getDapData("site", ids);
        console.log("🚀 ~ DapAttenteConverterController ~ tps:", tps)
        
    }
}