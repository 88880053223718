import { trunc } from 'lodash';

TicketSearchController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'TicketsService',
	'notification',
	'$filter',
	'_',
	'$uibModal',
	'$timeout'
];

export default function TicketSearchController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	TicketsService,
	notification,
	$filter,
	_,
	$uibModal,
	$timeout
) {
	const vm = this;
	const watchers = [];

	vm.$onInit = async () => {
		console.log('vm.$onInit = ()');
		vm.loading = false;
		vm.params = $stateParams;
		vm.state = $state;
		vm.$filter = $filter;
		vm._ = _;
		vm.ModalService = ModalService;
		vm.$uibModal = $uibModal;
		vm.tickets = [];
		vm.isCreate = false;
		vm.hasCustomFieldEnabled = false;
		vm.customFieldHeader = undefined;

		vm.ongletOpen = {
			isSearchOpen: true,
			isTicketsOpen: false
		};

		vm.getCustomField = getCustomFieldParam;
		vm.getCustomField();

		vm.loadTickets = loadTickets;
		vm.annuler = annuler;
		vm.openAnnuleRemplace = openAnnuleRemplace;
		vm.annuleRemplaceTicket = annuleRemplaceTicket;
		vm.openAnnulation = openAnnulation;
		vm.openUpdate = openUpdate;
		vm.updateTicket = updateTicket;

		vm.getChantiersRecherche = getChantiersRecherche;
		vm.getChantiersRemplace = getChantiersRemplace;
		vm.getProduits = getProduits;
		vm.getCommandesRecherche = getCommandesRecherche;
		vm.getCommandesRemplace = getCommandesRemplace;
		vm.getClients = getClients;
		vm.getClientsRemplace = getClientsRemplace;
		vm.changeClientRemplace = changeClientRemplace;
		vm.getTransporteursRecherche = getTransporteursRecherche;
		vm.getTransporteursRemplace = getTransporteursRemplace;
		vm.getChauffeursRemplace = getChauffeursRemplace;
		vm.getSites = getSites;
		vm.getLieux = getLieux;
		vm.getLieuxRemplace = getLieuxRemplace;
		vm.getZones = getZones;
		vm.getElementsSelectionnables = getElementsSelectionnables;
		vm.selectTransporteurReplace = selectTransporteurReplace;
		vm.change = change;
		vm.changeProduitformule = changeProduitformule;
		vm.changeFrancoToFalse = changeFrancoToFalse;

		vm.dateEcheanceFormule = null;
		vm.messageFormule = 'Masquer produits avec formules expirées';

		vm.isAQP = false;
		vm.isTransportFacturerReplace = '0';
		vm.isTransportFacturerSearch = '0';
		vm.isNonFacturableSearch = '0';
		vm.isNonFacturableReplace = '0';
		vm.isEntreeSortieSearch = '2';
		vm.isEntreeSortieReplace = '2';
		vm.isAchatVenteSearch = '2';
		vm.isAchatVenteReplace = '2';
		vm.isTransportFrancoSearch = '0';
		vm.isTransportFrancoReplace = '0';
		vm.isCessionInterneSearch = '0';
		vm.isCessionInterneReplace = '0';
		vm.isDeleteCommande = '0';
		vm.isDeleteChantier = '0';
		vm.isDeleteChauffeur = '0';
		vm.isDeleteCustomField = '0';
		vm.isDeleteTransporteur = '0';
		vm.isDeleteLieu = '0';
		vm.isDeleteZone = '0';
	};

	async function getCustomFieldParam()
	{
		vm.customFieldHeader = await TicketsService.getCustomFieldHeader();
		if (vm.customFieldHeader) {
			$timeout(() => {
				vm.hasCustomFieldEnabled = true;
			});
		}
	}

	async function init() {
		await getZones();
	}

	init();

	async function loadTickets() {
		startLoading();
		try {
			vm.tickets = [];
			const commandeId = vm.commandeSearch ? vm.commandeSearch.id : null;
			const clientId = vm.clientSearch ? vm.clientSearch.id : null;
			const siteId = vm.siteSearch ? vm.siteSearch.id : null;
			const chantierId = vm.chantierSearch ? vm.chantierSearch.id : null;
			const produitId = vm.produitSearch ? vm.produitSearch.id : null;
			const transporteurId = vm.transporteurSearch ? vm.transporteurSearch.id : null;
			const isTransportFacturer = vm.isTransportFacturerSearch == '0' ? null : vm.isTransportFacturerSearch == '1' ? true : false;
			const isNonFacturable = vm.isNonFacturableSearch == '0' ? null : vm.isNonFacturableSearch == '1' ? true : false;
			const isEntreeSortie = vm.isEntreeSortieSearch == '2' ? null : vm.isEntreeSortieSearch;
			const isAchatVente = vm.isAchatVenteSearch == '2' ? null : vm.isAchatVenteSearch;
			const isTransportFranco = vm.isTransportFrancoSearch == '0' ? null : vm.isTransportFrancoSearch == '1' ? true : false;
			const isCessionInterne = vm.isCessionInterneSearch == '0' ? null : vm.isCessionInterneSearch == '1' ? true : false;
			const lieuId = vm.lieuSearch ? vm.lieuSearch.id : null;
			const zoneId = vm.zoneSearch ? vm.zoneSearch : null;

			vm.tickets = await TicketsService.getTicketSearch(
				vm.dateDebut,
				vm.dateFin,
				commandeId,
				clientId,
				chantierId,
				produitId,
				vm.numeroTicketSearch,
				siteId,
				transporteurId,
				isTransportFacturer,
				isNonFacturable,
				isEntreeSortie,
				isAchatVente,
				isTransportFranco,
				lieuId,
				zoneId,
				isCessionInterne
			);

			vm.isCreate = false;
			if (vm.tickets.length > 0) {
				vm.ongletOpen = {
					isSearchOpen: true,
					isTicketsOpen: true,
					isReplaceOpen: false
				};
				vm.isEnabled = isProduitEnabled();
				isSingleClient();
				isSingleChantier();
				isSingleCommande();
				isSingleTransporteur();
				isSingleChauffeur();
				vm.lastDate = getLastDate();
				getSiteCommercialId();

				if (vm.isEnabled) {
					changeProduitformule();
				}

				vm.isAQP = await checkAQP();
				resetInput();
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	function resetInput() {
		vm.commandeReplace = null;
		vm.clientReplace = null;
		vm.chantierReplace = null;
		vm.transporteurReplace = null;
		vm.chauffeurReplace = null;
		vm.idProduit = null;
		vm.idSiteProducteur = null;
		vm.referenceCommandeReplace = null;
		vm.isTransportFacturerReplace = '0';
		vm.isNonFacturableReplace = '0';
		vm.isEntreeSortieReplace = '2';
		vm.isAchatVenteReplace = '2';
		vm.isTransportFrancoReplace = '0';
		vm.isCessionInterneReplace = '0';
		vm.isDeleteCommande = '0';
		vm.isDeleteChantier = '0';
		vm.isDeleteChauffeur = '0';
		vm.isDeleteTransporteur = '0';
		vm.isDeleteLieu = '0';
		vm.isDeleteZone = '0';
		vm.zoneReplace = null;
		vm.getCustomField();
	}

	function checkAQP() {
		for (let index = 0; index < vm.tickets.length; index++) {
			const element = vm.tickets[index];
			if (element.isAQP == true) {
				return true;
			}
		}
		return false;
	}

	function getSiteCommercialId() {
		if (vm.tickets.length == 1) {
			vm.siteCommercialId = vm.tickets[0].idSite;
		} else {
			vm.siteCommercialId = vm.siteSearch ? vm.siteSearch.id : null;
		}
	}

	function getSiteCommercialIdByTickets() {
		if (vm.tickets.length > 0) {
			return vm.tickets[0].idSite;
		}
		return null;
	}

	async function changeProduitformule() {
		if (vm.dateEcheanceFormule == null) {
			vm.dateEcheanceFormule = vm.lastDate;
			vm.messageFormule = 'Afficher produits avec formules expirées';
		} else {
			vm.dateEcheanceFormule = null;
			vm.messageFormule = 'Masquer produits avec formules expirées';
		}

		await getElementsSelectionnables();
	}

	function isProduitEnabled() {
		let response = true;
		vm.isEnabledProduit = true;
		vm.isEnabledSite = true;
		const idSite = vm.tickets[0].idSite;

		vm.tickets.forEach(function (element) {
			if (element.produits && element.produits.length > 1) {
				response = false;
				vm.isEnabledProduit = false;
				return false;
			}
			if (element.idSite != idSite) {
				response = false;
				vm.isEnabledSite = false;
				return false;
			}
		}, vm);
		return response;
	}

	function isSingleClient() {
		const idClient = vm.tickets[0].idClient;
		vm.clientReplace = {
			id: vm.tickets[0].idClient,
			libelle: vm.tickets[0].nomClient
		};
		vm.tickets.forEach(function (element) {
			if (element.idClient != idClient) {
				vm.clientReplace = null;
				return false;
			}
		}, vm);
	}

	function isSingleTransporteur() {
		const idTransporteur = vm.tickets[0].idTransporteur;
		vm.transporteurReplace = {
			id: vm.tickets[0].idTransporteur,
			libelle: vm.tickets[0].nomTransporteur
		};
		vm.tickets.forEach(function (element) {
			if (element.idTransporteur != idTransporteur) {
				vm.transporteurReplace = null;
				return false;
			}
		}, vm);
	}

	function isSingleChauffeur() {
		const idChauffeur = vm.tickets[0].idChauffeur;
		vm.chauffeurReplace = {
			id: vm.tickets[0].idChauffeur,
			nomPrenom: vm.tickets[0].nomChauffeur
		};
		vm.tickets.forEach(function (element) {
			if (element.idChauffeur != idChauffeur) {
				vm.chauffeurReplace = null;
				return false;
			}
		}, vm);
	}

	function isSingleChantier() {
		const idChantier = vm.tickets[0].idChantier;
		vm.chantierReplace = {
			id: vm.tickets[0].idChantier,
			libelle: vm.tickets[0].nomChantier
		};
		vm.tickets.forEach(function (element) {
			if (element.idChantier != idChantier) {
				vm.chantierReplace = null;
				return false;
			}
		}, vm);
	}

	function isSingleCommande() {
		const idCommande = vm.tickets[0].idCommande;
		vm.commandeReplace = {
			id: vm.tickets[0].idCommande,
			codeLibelle: vm.tickets[0].commande
		};
		vm.tickets.forEach(function (element) {
			if (element.idCommande != idCommande) {
				vm.commandeReplace = null;
				return false;
			}
		}, vm);
	}

	function getLastDate() {
		let date = vm.tickets[0].dateHeureLivraison;
		vm.tickets.forEach(function (element) {
			if (element.dateHeureLivraison > date) {
				date = element.dateHeureLivraison;
			}
		}, vm);
		return date;
	}

	function annuler() {
		$state.go('tickets.list');
	}

	async function getChantiersRecherche(valeur) {
		const clientId = vm.clientSearch ? vm.clientSearch.id : null;
		const chantiers = await TicketsService.getChantiers(valeur, clientId);
		return chantiers;
	}

	async function getTransporteursRecherche(valeur) {
		const transporteurs = await TicketsService.getTransporteurs(valeur);
		return transporteurs;
	}

	async function getChantiersRemplace(valeur) {
		let clientId = vm.clientReplace ? vm.clientReplace.id : null;

		if (clientId == null) {
			const idClients = [...new Set(vm.tickets.map((item) => item.idClient))];
			if (idClients.length == 1) {
				clientId = idClients[0];
			}
		}

		const chantiers = await TicketsService.getChantiers(valeur, clientId);
		return chantiers;
	}

	async function getProduits(valeur) {
		const siteComId = vm.siteSearch ? vm.siteSearch.id : null;
		const clientId = vm.clientSearch ? vm.clientSearch.id : null;

		const produits = await TicketsService.getProduits(valeur, clientId, siteComId);
		return produits;
	}

	async function getCommandesRecherche(valeur) {
		let date = null;
		if (vm.dateDebut) {
			date = vm.dateDebut;
		}
		if (vm.dateFin) {
			date = vm.dateFin;
		}
		const clientId = vm.clientSearch ? vm.clientSearch.id : null;
		const chantierId = vm.chantierSearch ? vm.chantierSearch.id : null;
		const commandes = await TicketsService.getCommandes(valeur, date, clientId, chantierId);
		return commandes;
	}

	async function getCommandesRemplace(valeur) {
		const clientId = vm.clientReplace ? vm.clientReplace.id : null;
		let chantierId = vm.chantierReplace ? vm.chantierReplace.id : null;

		if (chantierId == null) {
			const idChantiers = [...new Set(vm.tickets.map((item) => item.idChantier))];
			if (idChantiers.length == 1) {
				chantierId = idChantiers[0];
			}
		}

		const commandes = await TicketsService.getCommandes(valeur, vm.lastDate, clientId, chantierId);
		return commandes;
	}

	async function getClients(valeur) {
		const clients = await TicketsService.getClients(valeur);
		return clients;
	}

	async function getClientsRemplace(valeur) {
		const clients = await TicketsService.getClientsRemplace(valeur, vm.siteSearch ? vm.siteSearch.id : null);
		return clients;
	}

	function changeClientRemplace() {
		vm.chantierReplace = null;
	}

	async function getSites(valeur) {
		const sites = await TicketsService.getSitesCommerciaux(valeur);
		return sites;
	}

	async function getTransporteursRemplace(valeur) {
		const idSiteCom = getSiteCommercialIdByTickets();
		const transporteurs = await TicketsService.getTransporteursRemplace(valeur, idSiteCom);
		return transporteurs;
	}

	async function getChauffeursRemplace(valeur) {
		const transporteurId = vm.transporteurReplace ? vm.transporteurReplace.id : null;
		const chauffeurs = await TicketsService.getChauffeursRemplace(valeur, transporteurId);
		return chauffeurs;
	}

	async function getLieux(valeur) {
		const lieux = await TicketsService.getLieux(valeur);
		return lieux;
	}

	async function getLieuxRemplace(valeur) {
		const lieux = await TicketsService.getLieux(valeur);
		return lieux;
	}

	async function getZones() {
		startLoading();
		try {
			const idSite = vm.siteSearch ? vm.siteSearch.id : null;
			vm.zones = await TicketsService.getZones(idSite);
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function openUpdate() {
		let messageRemplace = $translate.instant('TICKETS.REMPLACE.MESSAGE_UPDATE');
		if (vm.clientReplace || vm.chantierReplace || vm.isDeleteChantier == '1') {
			messageRemplace = $translate.instant('TICKETS.REMPLACE.MESSAGE_REMPLACE_EMPTY');
		}
		const modalInstance = ModalService.confirm({
			modalTitle: $translate.instant('TICKETS.REMPLACE.TITLE_UPDATE'),
			modalMsg: messageRemplace,
			headerClass: 'modal-danger'
		});

		modalInstance.result.then(async function () {
			startLoading();
			try {
				await vm.updateTicket();

				vm.ongletOpen = {
					isSearchOpen: true,
					isTicketsOpen: true,
					isReplaceOpen: false
				};
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		});
	}

	async function updateTicket() {
		const ticketIds = [];
		vm.tickets.forEach(function (element) {
			ticketIds.push(element.id);
		}, vm);

		try {
			const commandeId = vm.commandeReplace ? vm.commandeReplace.id : null;
			const clientId = vm.clientReplace ? vm.clientReplace.id : null;
			const chantierId = vm.chantierReplace ? vm.chantierReplace.id : null;
			const transporteurId = vm.transporteurReplace ? vm.transporteurReplace.id : null;
			const chauffeurId = vm.chauffeurReplace ? vm.chauffeurReplace.id : null;
			const isTransportFacturer = vm.isTransportFacturerReplace == '0' ? null : vm.isTransportFacturerReplace == '1' ? true : false;
			const isNonFacturable = vm.isNonFacturableReplace == '0' ? null : vm.isNonFacturableReplace == '1' ? true : false;
			const isEntreeSortie = vm.isEntreeSortieReplace == '2' ? null : vm.isEntreeSortieReplace;
			const isAchatVente = vm.isAchatVenteReplace == '2' ? null : vm.isAchatVenteReplace;
			const isTransportFranco = vm.isTransportFrancoReplace == '0' ? null : vm.isTransportFrancoReplace == '1' ? true : false;
			const isCessionInterne = vm.isCessionInterneReplace == '0' ? null : vm.isCessionInterneReplace == '1' ? true : false;
			const lieuId = vm.lieuReplace ? vm.lieuReplace.id : null;
			const zoneId = vm.zoneReplace ? vm.zoneReplace : null;
			const isDeleteCommande = vm.isDeleteCommande == '0' ? false : true;
			const isDeleteChantier = vm.isDeleteChantier == '0' ? false : true;
			const isDeleteChauffeur = vm.isDeleteChauffeur == '0' ? false : true;
			const isDeleteTransporteur = vm.isDeleteTransporteur == '0' ? false : true;
			const isDeleteLieu = vm.isDeleteLieu == '0' ? false : true;
			const isDeleteZone = vm.isDeleteZone == '0' ? false : true;
			const isCustomField = vm.isDeleteCustomField == '0' ? vm.customFieldReplace : null;
			const isDeleteCustomField = vm.isDeleteCustomField == '0' ? false : true;
			vm.tickets = await TicketsService.updateTicket(
				ticketIds,
				commandeId,
				clientId,
				chantierId,
				vm.idProduit,
				vm.idSiteProducteur,
				vm.referenceCommandeReplace,
				transporteurId,
				chauffeurId,
				isCustomField,
				isTransportFacturer,
				isNonFacturable,
				isEntreeSortie,
				isAchatVente,
				isTransportFranco,
				lieuId,
				zoneId,
				isDeleteCommande,
				isDeleteChantier,
				isDeleteChauffeur,
				isDeleteTransporteur,
				isDeleteLieu,
				isDeleteZone,
				isCessionInterne,
				isDeleteCustomField,
			);

			resetInput();
			notification.success('TICKETS.UPDATED');
		} catch (ex) {
			notification.error(ex.data);
			return false;
		} finally {
			stopLoading();
		}
	}

	async function openAnnuleRemplace() {
		let messageRemplace = $translate.instant('TICKETS.REMPLACE.MESSAGE_REMPLACE');
		if (vm.clientReplace || vm.chantierReplace || vm.isDeleteChantier == '1') {
			messageRemplace = $translate.instant('TICKETS.REMPLACE.MESSAGE_REMPLACE_EMPTY');
		}
		const modalInstance = ModalService.confirm({
			modalTitle: $translate.instant('TICKETS.REMPLACE.TITLE_REMPLACE'),
			modalMsg: messageRemplace,
			headerClass: 'modal-danger'
		});

		modalInstance.result.then(async function () {
			startLoading();
			try {
				await vm.annuleRemplaceTicket();

				vm.ongletOpen = {
					isSearchOpen: true,
					isTicketsOpen: true,
					isReplaceOpen: false
				};
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		});
	}

	async function annuleRemplaceTicket() {
		const ticketIds = [];
		vm.tickets.forEach(function (element) {
			ticketIds.push(element.id);
		}, vm);

		try {
			const commandeId = vm.commandeReplace ? vm.commandeReplace.id : null;
			const clientId = vm.clientReplace ? vm.clientReplace.id : null;
			const chantierId = vm.chantierReplace ? vm.chantierReplace.id : null;
			const transporteurId = vm.transporteurReplace ? vm.transporteurReplace.id : null;
			const chauffeurId = vm.chauffeurReplace ? vm.chauffeurReplace.id : null;
			const isTransportFacturer = vm.isTransportFacturerReplace == '0' ? null : vm.isTransportFacturerReplace == '1' ? true : false;
			const isNonFacturable = vm.isNonFacturableReplace == '0' ? null : vm.isNonFacturableReplace == '1' ? true : false;
			const isEntreeSortie = vm.isEntreeSortieReplace == '2' ? null : vm.isEntreeSortieReplace;
			const isAchatVente = vm.isAchatVenteReplace == '2' ? null : vm.isAchatVenteReplace;
			const isTransportFranco = vm.isTransportFrancoReplace == '0' ? null : vm.isTransportFrancoReplace == '1' ? true : false;
			const isCessionInterne = vm.isCessionInterneReplace == '0' ? null : vm.isCessionInterneReplace == '1' ? true : false;
			const lieuId = vm.lieuReplace ? vm.lieuReplace.id : null;
			const zoneId = vm.zoneReplace ? vm.zoneReplace : null;
			const isDeleteCommande = vm.isDeleteCommande == '0' ? false : true;
			const isDeleteChantier = vm.isDeleteChantier == '0' ? false : true;
			const isDeleteChauffeur = vm.isDeleteChauffeur == '0' ? false : true;
			const isDeleteTransporteur = vm.isDeleteTransporteur == '0' ? false : true;
			const isDeleteLieu = vm.isDeleteLieu == '0' ? false : true;
			const isDeleteZone = vm.isDeleteZone == '0' ? false : true;
			
			const isCustomField = vm.isDeleteCustomField == '0' ? vm.customFieldReplace : null;
			const isDeleteCustomField = vm.isDeleteCustomField == '0' ? false : true;
			vm.tickets = await TicketsService.annuleRemplaceTicket(
				ticketIds,
				commandeId,
				clientId,
				chantierId,
				vm.idProduit,
				vm.idSiteProducteur,
				vm.referenceCommandeReplace,
				transporteurId,
				chauffeurId,
				isTransportFacturer,
				isNonFacturable,
				isEntreeSortie,
				isAchatVente,
				isCustomField,
				isTransportFranco,
				lieuId,
				zoneId,
				isDeleteCommande,
				isDeleteChantier,
				isDeleteChauffeur,
				isDeleteTransporteur,
				isDeleteLieu,
				isDeleteZone,
				isCessionInterne,
				isDeleteCustomField
			);
			vm.isCreate = true;
			resetInput();
			notification.success('TICKETS.UPDATED');
		} catch (ex) {
			notification.error(ex.data);
			return false;
		} finally {
			stopLoading();
		}
	}

	function openAnnulation() {
		vm.$uibModal
			.open({
				template: '<annulation-popup modal-instance="$ctrl.uibModalInstance"></annulation-popup>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'l',
				backdrop: false
			})
			.result.then(
				function (result) {
					if (result) {
						annulationTicket(result);
					}
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	async function annulationTicket(data) {
		startLoading();
		try {
			const ticketIds = [];
			vm.tickets.forEach(function (element) {
				ticketIds.push(element.id);
			}, vm);
			await TicketsService.annulationTicket(ticketIds, data.idMotif, data.commentaire);
			notification.success('TICKETS.CANCELED');
			vm.$onInit();
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function change() {
		vm.idProduit = vm.produitReplace[0].produitId;
		vm.idSiteProducteur = vm.produitReplace[0].producteurId;
	}

	async function selectTransporteurReplace() {
		vm.chauffeurReplace = null;
	}

	async function getElementsSelectionnables() {
		//vm.chantierReplace = null;
		//vm.commandeReplace = null;

		const filtre = {
			societeId: null,
			siteCommercialId: vm.siteCommercialId,
			clientId: vm.clientReplace ? vm.clientReplace.id : null,
			chantierId: vm.chantierReplace ? vm.chantierReplace.id : null,
			entreeSortie: null,
			achatVente: 1,
			date: vm.dateEcheanceFormule
		};
		try {
			// vm.produitsSelectionnables = await TicketsService.getProduitsSelectionnables(filtre);
			vm.produitsLiesListe = await TicketsService.getProduitsLies(filtre);
			vm.produitsLies = await prepareListeElementsLies(vm.produitsLiesListe);
		} catch (err) {
			if (err.data) {
				vm.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	async function prepareListeElementsLies(elementsATraiter) {
		const elementsSelectionnablesOrdonnes = vm.$filter('orderBy')(elementsATraiter, 'producteurLibelle');
		let tmp = null;
		const temp = [];
		for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
			const currentValue = elementsSelectionnablesOrdonnes[index];
			const toReturn = [];

			if (currentValue.hasOwnProperty('modeDeclenchement')) {
				currentValue.idComposant = currentValue.id;
				toReturn.push(currentValue);
			} else {
				//#region Gestion des groupes
				if (tmp == null) {
					//creation du premier groupe
					tmp = currentValue.producteurId;
					toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
				} else {
					if (tmp != currentValue.producteurId) {
						//création des autres groupes
						toReturn.push({ msGroup: false });
						tmp = currentValue.producteurId;
						toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
					}
				}
				//#endregion
				currentValue.libelle = currentValue.produitCode + ' - ' + currentValue.produitLibelle;
				currentValue.valeur = false;
				currentValue.idComposant = currentValue.produitId;
				currentValue.code = currentValue.produitCode;
				currentValue.IdProducteur = currentValue.producteurId;
				toReturn.push(currentValue);

				if (index == elementsSelectionnablesOrdonnes.length - 1) {
					toReturn.push({ msGroup: false });
				}
			}
			temp.push(toReturn);
		}
		const sorties = _.flatten(temp);
		return new Promise((resolve) => resolve(sorties));
	}

	//Si pas de transport à facturer, on passe le franco à faux
	function changeFrancoToFalse(isTransportFacturerReplace) {
		if (isTransportFacturerReplace === '2') {
			vm.isTransportFrancoReplace = '2';
		}
	}
}
