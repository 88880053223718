import Site from './site.model';
import L from 'leaflet/dist/leaflet';
import 'leaflet-easyprint';
import 'leaflet-measure/dist/leaflet-measure.fr';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.min';

export default class SiteFormController {
	static $inject = [
		'$scope',
		'$timeout',
		'$state',
		'$stateParams',
		'SitesCommunicationService',
		'SitesService',
		'notification',
		'$location',
		'$anchorScroll',
		'MassiaApplicationService',
		'$uibModal',
		'$translate',
		'_',
		'RouterHistoryService',
		'globalizationManagementService',
		'moment',
		'ModalService',
		'ProduitsService',
		'PaginationService',
		'MOPService'
	];

	certificats = [];
	accreditationToDelete = [];

	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		SitesCommunicationService,
		SitesService,
		notification,
		$location,
		$anchorScroll,
		MassiaApplicationService,
		$uibModal,
		$translate,
		_,
		RouterHistoryService,
		globalizationManagementService,
		moment,
		ModalService,
		ProduitsService,
		PaginationService,
		MOPService
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.SitesCommunicationService = SitesCommunicationService;
		this.SitesService = SitesService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$uibModal = $uibModal;
		this.$translate = $translate;
		this.ModalService = ModalService;
		this.ProduitsService = ProduitsService;
		this.PaginationService = PaginationService;
		this._ = _;
		this.RouterHistoryService = RouterHistoryService;
		this.globalizationManagementService = globalizationManagementService;
		this.moment = moment;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.MOPService = MOPService;
	}

	async $onInit() {
		this.application = this.MassiaApplicationService.getApplication();
		this.site = {};
		this.loading = false;
		this.listeGenresSite = [];
		this.produits = [];
		this.form = {
			entete: {},
			map: {},
			caracteristiquesUtilisateur: {},
			caracteristiquesProducteur: {},
			caracteristiquesClient: {},
			caracteristiquesFournisseur: {},
			caracteristiquesTransporteur: {},
			caracteristiquesLaboratoire: {},
			caracteristiquesProprietaire: {},
			caracteristiquesLocataire: {},
			caracteristiquesAuditeur: {},
			objectifs: {},
			indicateurs: {},
			niveaux: {},
			coordoneesBancaires: {},
			qualib: {}
			// certificationCe: {}
		};

		this.itemsByPageOptions = [20, 100, 200];

		if (!this.modalInstance) {
			this.genreSite = this.$stateParams.genre;
		} else {
			this.genrePourOuverture = this.genreSite;
			switch (this.genreSite) {
				case 1:
					this.genreSite = 'commerciaux';
					break;
				case 2:
					this.genreSite = 'clients';
					break;
				case 4:
					this.genreSite = 'producteurs';
					break;
				case 8:
					this.genreSite = 'fournisseurs';
					break;
				case 16:
					this.genreSite = 'transporteurs';
					break;
				case 32:
					this.genreSite = 'laboratoires';
					break;
				case 64:
					this.genreSite = 'proprietaires';
					break;
				case 128:
					this.genreSite = 'locataires';
					break;
				case 256:
					this.genreSite = 'auditeurs';
					break;
			}
		}
		this.listeGenresSite = await this.SitesService.getGenreSite();
		await this.updateGenre();

		await this.reset();
		this.initCarte();
		this.getDomaine();
	}

	getDomaine() {
		switch (this.genreSite) {
			case 'commerciaux':
				this.domaine = 'siteutil';
				break;
			case 'clients':
				this.domaine = 'siteclient';
				break;
			case 'producteurs':
				this.domaine = 'siteprod';
				break;
			case 'transporteurs':
				this.domaine = 'sitetransp';
				break;
			case 'fournisseurs':
				this.domaine = 'sitefourn';
				break;
			case 'laboratoires':
				this.domaine = 'sitelabo';
				break;
			case 'proprietaires':
				this.domaine = 'siteproprio';
				break;
			case 'locataires':
				this.domaine = 'sitelocat';
				break;
			case 'auditeurs':
				this.domaine = 'siteaudit';
				break;
			default:
				break;
		}
	}

	async updateGenre() {
		switch (this.genreSite) {
			case 'commerciaux':
				this.site.genreSite = 1;
				this.site.genreLibelle = this.$translate.instant('SITES.ISCOMMERCIAL'); //'Commercial';
				break;
			case 'clients':
				this.site.genreSite = 2;
				this.site.genreLibelle = this.$translate.instant('SITES.ISCLIENT'); //'Client';
				break;
			case 'producteurs':
				this.site.genreSite = 4;
				this.site.genreLibelle = this.$translate.instant('SITES.ISPRODUCTEUR'); //'Producteur';
				break;
			case 'transporteurs':
				this.site.genreSite = 16;
				this.site.genreLibelle = this.$translate.instant('SITES.ISTRANSPORTEUR'); //'Transporteur';
				break;
			case 'fournisseurs':
				this.site.genreSite = 8;
				this.site.genreLibelle = this.$translate.instant('SITES.ISFOURNISSEUR'); //'Fournisseur';
				break;
			case 'laboratoires':
				this.site.genreSite = 32;
				this.site.genreLibelle = this.$translate.instant('SITES.ISLABORATOIRE'); //'Laboratoire';
				break;
			case 'proprietaires':
				this.site.genreSite = 64;
				this.site.genreLibelle = this.$translate.instant('SITES.ISPROPRIETAIRE'); //'Proprietaire';
				break;
			case 'locataires':
				this.site.genreSite = 128;
				this.site.genreLibelle = this.$translate.instant('SITES.ISLOCATAIRE'); //'Locataire';
				break;
			case 'auditeurs':
				this.site.genreSite = 256;
				this.site.genreLibelle = this.$translate.instant('SITES.ISAUDITEUR'); //'Auditeur';
				break;
			default:
				break;
		}
	}

	open(domaine) {
		const _this = this;
		_this.source = this.site;
		_this.domaine = domaine;
		this.$uibModal
			.open({
				template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.domaine = _this.domaine;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateSiteTimeout);
		this.MOPService.resetMop();
	}

	async reset() {
		this.startLoading();
		this.tmp = {
			configurationBalance: null
		};

		const data = {
			id: !this.modalInstance ? this.$stateParams.id : undefined
		};

		if (data.id) {
			try {
				data.entete = await this.SitesService.getSiteEnteteById(data.id, this.site.genreSite, this.MassiaApplicationService.getApplication());

				data.caracteristiquesUtilisateur = await this.SitesService.getSiteValeursCaracteristiquesUtilisateurAssociationsById(data.id);
				data.caracteristiquesProducteur = await this.SitesService.getSiteValeursCaracteristiquesProducteurAssociationsById(data.id);
				data.caracteristiquesFournisseur = await this.SitesService.getSiteValeursCaracteristiquesFournisseurAssociationsById(data.id);
				data.niveaux = await this.SitesService.getSiteValeursNiveauxById(data.id);
				data.objectifs = await this.SitesService.getSiteObjectifById(data.id);
				data.caracteristiquesTransporteur = await this.SitesService.getSiteValeursCaracteristiquesTransporteurAssociationsById(data.id);
				data.caracteristiquesClient = await this.SitesService.getSiteValeursCaracteristiquesClientAssociationsById(data.id);
				data.coordonneesBancaires = await this.SitesService.getCoordonnees(data.id);
				data.genreSite = this.site.genreSite;
				data.genreLibelle = this.site.genreLibelle;

				switch (this.genreSite) {
					case 'commerciaux':
						this.domaineDocument = 3;
						break;
					case 'clients':
						this.domaineDocument = 4;
						break;
					case 'producteurs':
						this.domaineDocument = 2;
						break;
					case 'transporteurs':
						this.domaineDocument = 6;
						break;
					case 'fournisseurs':
						this.domaineDocument = 5;
						break;
					case 'laboratoires':
						this.domaineDocument = 31;
						break;
					case 'proprietaires':
						this.domaineDocument = 32;
						break;
					case 'locataires':
						this.domaineDocument = 33;
						break;
					case 'auditeurs':
						this.domaineDocument = 34;
						break;
					default:
						break;
				}
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
				this.annuler();
			}

			this.tmp.configurationBalance = data.entete.configurationBalance;
			this.site = new Site(data, this.application);
		} else {
			this.site = new Site(data, this.application);

			// Initialisation du client à partir du prospect si la creation est faite à partir d'un prospect
			// lors de la transformation d'une proposition en commande
			if (this.$stateParams.fromProspect) {
				const prospect = await this.SitesService.getProspect(this.$stateParams.prospectId);
				const proposition = await this.SitesService.getProposition(this.$stateParams.propositionId);
				//this.site.entete.etatCompteSites = [proposition.offre.idSociete];

				//this.site.entete.societeId = proposition.offre.idSociete;
				this.site.entete.paysId = prospect.paysId;
				this.site.entete.raisonSociale = prospect.civilite;
				this.site.entete.libelle = prospect.libelle;
				this.site.entete.adresse = prospect.adresse;
				this.site.entete.ville = prospect.ville;
				this.site.entete.codePostal = prospect.codePostal;
				this.site.entete.telephone = prospect.telephone;
				this.site.entete.fax = prospect.fax;
				this.site.entete.mail = prospect.email;
				this.site.entete.website = prospect.website;
				this.site.entete.fromWhatName = 'prospect';
				this.site.entete.fromWhatId = this.$stateParams.prospectId;
			}

			if (this.$stateParams.personneId) {
				const personne = await this.SitesService.getPersonneName(this.$stateParams.personneId);
				this.site.entete.personneId = personne.id;
				this.site.entete.personne = personne.libelle;
			}

			// SL recuperation des donnees du client divers via l Id pour pre-remplir le formulaire du client
			if (this.$stateParams.fromClientDivers) {
				this.clientDivers = await this.SitesService.getClientDivers(this.$stateParams.clientDiversId);
				this.site.entete.adresse = this.clientDivers.adresse;
				this.site.entete.codePostal = this.clientDivers.codePostal;
				this.site.entete.fax = this.clientDivers.fax;
				this.site.entete.paysId = this.clientDivers.idPays;
				this.site.entete.libelle = this.clientDivers.libelle;
				this.site.entete.mail = this.clientDivers.mail;
				this.site.entete.telephone = this.clientDivers.telephone;
				this.site.entete.ville = this.clientDivers.ville;
				this.site.entete.website = this.clientDivers.web;
				this.site.entete.raisonSociale = this.clientDivers.civilite;
				this.site.entete.fromWhatName = 'clientDivers';
				this.site.entete.fromWhatId = this.$stateParams.clientDiversId;
			}
			switch (this.genreSite) {
				case 'commerciaux':
					this.site.entete.isUtilisateur = true;
					this.domaineDocument = 3;
					break;
				case 'clients':
					this.site.entete.isClient = true;
					this.domaineDocument = 4;
					break;
				case 'producteurs':
					this.site.entete.isProducteur = true;
					this.domaineDocument = 2;
					break;
				case 'transporteurs':
					this.site.entete.isTransporteur = true;
					this.domaineDocument = 6;
					break;
				case 'fournisseurs':
					this.site.entete.isFournisseur = true;
					this.domaineDocument = 5;
					break;
				case 'laboratoires':
					this.site.entete.isLaboratoire = true;
					this.domaineDocument = 31;
					break;
				case 'proprietaires':
					this.site.entete.isProprietaire = true;
					this.domaineDocument = 32;
					break;
				case 'locataires':
					this.site.entete.isLocataire = true;
					this.domaineDocument = 33;
					break;
				case 'auditeurs':
					this.site.entete.isAuditeur = true;
					this.domaineDocument = 34;
					break;
				default:
					break;
			}
		}

		//otention du paramètre de coche auto des états comptes
		const cptOuvert = await this.SitesService.getCompteOuvertDefault();
		this.site.compteOuvertDefault = cptOuvert === '1' ? true : false;

		if (this.$stateParams.duplicate) {
			this.site.id = undefined;
			this.site.entete.code += '_copie';
		}

		this.ongletOpen = {
			isEnteteOpen: true,
			isMapOpen: true,
			isCaracteristiqueUtilisateurOpen: false,
			isCaracteristiqueClientOpen: false,
			isCaracteristiqueFournisseurOpen: false,
			isCaracteristiqueProducteurOpen: false,
			isCaracteristiqueTransporteurOpen: false,
			isCaracteristiqueLaboratoireOpen: false,
			isCaracteristiqueProprietaireOpen: false,
			isCaracteristiqueLocataireOpen: false,
			isCaracteristiqueAuditeurOpen: false,
			isProduitProducteurOpen: false,
			isProduitClientOpen: false,
			isNiveauxHierarchiqueOpen: false,
			isObjectifsOpen: false,
			isIndicateursOpen: false,
			isSpecificitesOpen: false,
			coordoneesBancairesOpen: false,
			etatCompteOpen: false,
			isImportBLOpen: false
		};

		this.afficheCarte();

		this.$timeout.cancel(this.updateSiteTimeout);
		this.updateSiteTimeout = this.$timeout(() => this.stopLoading());
	}

	async initCarte() {
		const _this = this;
		this.zoom = 5;
		this.longitude = 3.5;
		this.latitude = 45.5;
		if (this.site.longitude) {
			this.longitude = this.site.longitude;
		}
		if (this.site.latitude) {
			this.latitude = this.site.latitude;
		}
		if (this.site.longitude && this.site.latitude) {
			this.zoom = 15;
		}

		$('#map', async () => {
			this.map = L.map('map').setView([this.latitude, this.longitude], this.zoom); //par défaut

			this.map.scrollWheelZoom.disable();

			/*this.map.on('mapReady', function (map) {
                setTimeout(() => {
                    map.invalidateSize();
                }, 0);
            }, this);*/

			//Couches
			const Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				//z:niveau de zoom, x et y coordonnées du pavé
				attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				maxZoom: 20 //zoom maximum de la carte
			});
			Mapnik.addTo(this.map);

			//ajout du plan topo
			const topo = await this.planTopo();

			this.blueMarker = L.icon({
				iconUrl: 'assets/img/pin_GO.png',
				iconSize: [64, 64], // size of the icon
				iconAnchor: [8, 55] // point of the icon which will correspond to marker's location
			});
			this.marqueurSite = L.marker([this.latitude, this.longitude], {
				icon: this.blueMarker,
				draggable: 'true' /*, class:'leaflet-marker-pane'*/
			});
			this.marqueurSite.on(
				'dragend',
				function (event) {
					const marker = event.target;
					const position = marker.getLatLng();
					this.changePositionMarqueur(position, true);
					this.map.setView(position, 15);
				},
				this
			);
			this.marqueurSite.addTo(this.map);

			/*this.map.on('click', function (event) {
                //this.onMapClick(event);
                _this.changePositionMarqueur(event.latlng,true);
                _this.map.setView(event.latlng, 15);
                this.site.latitude = _this.site.latitude;
                this.site.longitude = _this.site.longitude;
            }, this);*/
			this.map.on('click', this.onMapClick, this);

			this.map.panTo(new L.LatLng(this.latitude, this.longitude));

			//bouton géolocalisation
			L.Control.Geolocate = L.Control.extend({
				onAdd: function (map) {
					const containerSup = L.DomUtil.create('div');
					containerSup.style.zIndex = '1200';
					const container = L.DomUtil.create('button', 'btn btn-primary btn btn-primary', containerSup);
					container.style.zIndex = '1200';
					const msgGeolocalise = _this.$translate.instant('SITES.GEOLOCALISE'); //ne fonctionne qu'avec copie du this
					container.title = msgGeolocalise;
					(container.onclick = function () {
						_this.geolocalise();
					}),
						_this;
					L.DomUtil.create('span', 'glyphicon glyphicon-map-marker', container);

					const container2 = L.DomUtil.create('button', 'btn btn-primary btn btn-primary', containerSup);
					container2.style.zIndex = '1200';
					const msgGeolocaliseMoi = _this.$translate.instant('SITES.GEOLOCALISE_MOI');
					container2.title = msgGeolocaliseMoi;
					(container2.onclick = function () {
						_this.geolocaliseMoi();
					}),
						_this;
					L.DomUtil.create('span', 'glyphicon glyphicon-screenshot', container2);
					return containerSup;
				},
				onRemove: function (map) {}
			});
			L.control.geolocate = function (opts) {
				return new L.Control.Geolocate(opts);
			};
			L.control.geolocate({ position: 'bottomleft' }).addTo(this.map);

			//plein écran
			const msgVoirPleinEcran = _this.$translate.instant('SITES.VOIR_PLEIN_ECRAN');
			const msgQuitterPleinEcran = _this.$translate.instant('SITES.QUITTER_PLEIN_ECRAN');
			this.map.addControl(
				new L.Control.Fullscreen({
					title: {
						false: msgVoirPleinEcran,
						true: msgQuitterPleinEcran
					}
				})
			);

			//impression
			const pngFileName = this.site.entete.libelle;
			const ep = L.easyPrint({
				title: _this.$translate.instant('EXPORTER'),
				position: 'topleft',
				sizeModes: ['Current', 'A4Portrait', 'A4Landscape'],
				exportOnly: true,
				filename: pngFileName,
				tileLayer: Mapnik,
				hideControlContainer: true
			});
			ep.addTo(this.map);

			//panel de gestion des couches
			const OSMFr = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
				//z:niveau de zoom, x et y coordonnées du pavé
				attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				maxZoom: 20 //zoom maximum de la carte
			});

			const baseLayer = {
				'OpenStreetMap Org': Mapnik,
				'OpenStreetMap France': OSMFr
			};
			const overlaysLayers = {};
			if (topo) {
				overlaysLayers['Plan topographique'] = topo;
			}

			L.control.layers(baseLayer, overlaysLayers, { collapsed: true }).addTo(this.map);

			//mesures
			const optionsMesures = {
				position: 'topright', //'bottomright',
				primaryLengthUnit: 'kilometers',
				secondaryLengthUnit: 'meters',
				primaryAreaUnit: 'hectares',
				secondaryAreaUnit: 'sqmeters',
				activeColor: '#FF0000',
				completedColor: '#0000FF'
			};
			const measureControl = new L.Control.Measure(optionsMesures);
			measureControl.addTo(this.map);
		});
	}

	onMapClick(event) {
		this.changeCoord(event);
		this.$scope.$broadcast('myEvent', []);
	}

	changeCoord(event) {
		this.changePositionMarqueur(event.latlng, true);
		this.map.setView(event.latlng, 15);
	}

	async planTopo() {
		if (this.$stateParams.id) {
			const planTopoSite = await this.SitesService.getPlanTopo(this.$stateParams.id);
			if (planTopoSite) {
				const mapMinZoom = 15;
				const mapMaxZoom = 20;
				const optionsTopo = {
					idSite: this.$stateParams.id,
					annee: planTopoSite.annee,
					minZoom: mapMinZoom,
					maxZoom: mapMaxZoom,
					opacity: 1.0,
					attribution: 'Rendered with <a href="http://www.maptiler.com/">MapTiler</a>',
					tms: false
				};
				const topo = L.tileLayer('assets/topo/{idSite}/{annee}/{z}/{x}/{y}.png', optionsTopo);
				topo.addTo(this.map);
				return topo;
			}
			return undefined;
		}
	}

	changePositionMarqueur(evtLatLgn, diffuseEntete) {
		this.marqueurSite.setLatLng(evtLatLgn).update();
		this.site.latitude = evtLatLgn.lat;
		this.site.longitude = evtLatLgn.lng;
		this.afficheCarte();
	}

	afficheCarte() {
		//est-ouest:latitude, nord-sud:longitude
		if (this.map && (this.longitude != this.site.longitude || this.latitude != this.site.latitude)) {
			//let zoom = this.zoom;
			let longitude = this.site.longitude;
			let latitude = this.site.latitude;
			if (!longitude || !latitude) {
				longitude = 3.5;
				latitude = 45.5;
				//zoom=5;
			}
			if (this.longitude != this.site.longitude || this.latitude != this.site.latitude) {
				this.longitude = longitude;
				this.latitude = latitude;
				this.map.panTo(new L.LatLng(this.latitude, this.longitude));
				//var latlng = {lat: this.latitude ,lng: this.longitude};
			}
		}
	}

	async geolocalise() {
		const coordonnees = await this.SitesService.geolocalise(this.site.entete);
		if (coordonnees && coordonnees.latitude) {
			const latLng = L.latLng(coordonnees.latitude, coordonnees.longitude);
			this.changePositionMarqueur(latLng, true);
			this.map.setView(latLng, 15);
		} else {
			this.notification.error('SITES.ADRESSE_POSITION_IMPOSSIBLE');
		}
	}

	async geolocaliseMoi() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => this.geolocalisationGPS(position));
		} else {
			this.notification.error('SITES.VOTRE_POSITION_IMPOSSIBLE');
		}
	}

	geolocalisationGPS(position) {
		const latLng = L.latLng(position.coords.latitude, position.coords.longitude);
		this.changePositionMarqueur(latLng, true);
		this.map.setView(latLng, 15);
	}

	async getProspect(prospectId) {
		return await this.SitesService.getProspect(prospectId);
	}

	// boutons de validation
	async sauvegarder() {
		if (this.checkValidity()) {
			this.startLoading();
			try {
				if (this.site.interface) {
					if (this.site.interface.id) {
						await this.SitesService.updateTransporteurInterface(this.site.id, this.site.interface);
					} else {
						await this.SitesService.createTransporteurInterface(this.site.id, this.site.interface);
					}
				}
				let id = false;
				this.site.entete.latitude = this.site.latitude;
				this.site.configurationBalance = this.tmp.configurationBalance;
				this.site.entete.longitude = this.site.longitude;
				if (this.site.id) {
					await this.SitesService.updateSite(this.site, this.application);
					this.notification.success('SITES.UPDATED');
					id = this.site.id;
				} else {
					id = await this.SitesService.createSite(this.site, this.application);

					if (this.$stateParams.fromProspect) {
						await this.SitesService.deleteProspect(this.$stateParams.prospectId, id);
					}

					if (this.modalInstance && this.idSitePourCompte && this.genrePourOuverture) {
						//ouvrir le compte
						await this.SitesService.ouvreComptePourSite(this.idSitePourCompte, id, this.genrePourOuverture);
					}
					this.notification.success('SITES.CREATED');
				}
				return id;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
				return false;
			} finally {
				this.stopLoading();
			}
		}
	}

	async appliquer() {
		this.RouterHistoryService.ignoreNextRoute();
		const id = await this.sauvegarder();
		if (id && this.site.id) {
			await this.reset();
			this.initCarte();
		} else if (id) {
			if (this.modalInstance) {
				this.modalInstance.dismiss();
			}
			this.$state.go(this.getUrlEdit(), { id: id, genre: this.genreSite });
		}
	}

	async confirmer() {
		const success = await this.sauvegarder();
		if (success) {
			this.annuler(success);
		}
	}

	annuler(success) {
		if (this.$stateParams.parentState == 'clients-divers.edit') {
			return this.$state.go('clients-divers.list');
		}
		// Differentes pages de retour possibles.
		// Si la creation du site est faite lors de la transformation en commande d'une proposition
		if (!this.RouterHistoryService.back()) {
			if (this.$stateParams.parentState == 'offres.commande') {
				this.$state.go('offres.commande', { id: this.$stateParams.propositionId, clientId: success });
			} else if (this.$stateParams.parentState == 'clients-divers.new') {
				this.$state.go('clients-divers.new');
			} else if (this.$stateParams.parentState == 'clients-divers.edit') {
				this.$state.go('clients-divers.edit', { id: 20 });
			} else {
				if (this.modalInstance) {
					this.modalInstance.dismiss();
				} else {
					this.$state.go(this.getUrlListe(), { genre: this.genreSite });
				}
			}
		}
	}

	getUrlListe() {
		switch (this.genreSite) {
			case 'commerciaux':
				return 'sites.list';
			case 'clients':
				return 'clients.list';
			case 'producteurs':
				return 'producteurs.list';
			case 'fournisseurs':
				return 'fournisseurs.list';
			case 'transporteurs':
				return 'transporteurs.list';
			case 'laboratoires':
				return 'laboratoires.list';
			case 'proprietaires':
				return 'proprietaires.list';
			case 'locataires':
				return 'locataires.list';
			case 'auditeurs':
				return 'auditeurs.list';
			default:
				return 'sites.list';
		}
	}

	getUrlEdit() {
		switch (this.genreSite) {
			case 'commerciaux':
				return 'sites.edit';
			case 'clients':
				return 'clients.edit';
			case 'producteurs':
				return 'producteurs.edit';
			case 'fournisseurs':
				return 'fournisseurs.edit';
			case 'transporteurs':
				return 'transporteurs.edit';
			case 'laboratoires':
				return 'laboratoires.edit';
			case 'proprietaires':
				return 'proprietaires.edit';
			case 'locataires':
				return 'locataires.edit';
			case 'auditeurs':
				return 'auditeurs.edit';
			default:
				return 'sites.edit';
		}
	}

	filtrer() {
		this.$state.go('donnee-filtrees.list', { id: this.site.id, type: 2 });
	}

	checkValidity() {
		//this.closeAllOnglets();
		let validity = true;

		//Check des états comptes
		//const appName = this.MassiaApplicationService.getApplication();
		//let type = null;
		// if (appName == 'gestion' && this.site.entete.isUtilisateur && this.verifEtatCompte('Commercial')) {
		// 	const cptOuvert = this.site.entete.etatCompteSites.find((e) => e.genreSite === 1 && e.isCompteSiteOuvert);
		// 	if (!cptOuvert) {
		// 		type = 'utilisateur';
		// 	}
		// }
		// if (this.site.entete.isClient && this.verifEtatCompte('Client')) {
		// 	const cptOuvert = this.site.entete.etatCompteSites.find((e) => e.genreSite === 2 && e.isCompteSiteOuvert);
		// 	if (!cptOuvert) {
		// 		type = 'client';
		// 	}
		// }
		// if (this.site.entete.isProducteur && this.verifEtatCompte('Producteur')) {
		// 	const cptOuvert = this.site.entete.etatCompteSites.find((e) => e.genreSite === 4 && e.isCompteSiteOuvert);
		// 	if (!cptOuvert) {
		// 		type = 'producteur';
		// 	}
		// }
		// if (appName == 'gestion' && this.site.entete.isFournisseur && this.verifEtatCompte('Fournisseur')) {
		// 	const cptOuvert = this.site.entete.etatCompteSites.find((e) => e.genreSite === 8 && e.isCompteSiteOuvert);
		// 	if (!cptOuvert) {
		// 		type = 'fournisseur';
		// 	}
		// }
		// if (this.site.entete.isTransporteur && this.verifEtatCompte('Transporteur')) {
		// 	const cptOuvert = this.site.entete.etatCompteSites.find((e) => e.genreSite === 16 && e.isCompteSiteOuvert);
		// 	if (!cptOuvert) {
		// 		type = 'transporteur';
		// 	}
		// }
		// if (this.site.entete.isProprietaire && this.verifEtatCompte('Propriétaire')) {
		// 	const cptOuvert = this.site.entete.etatCompteSites.find((e) => e.genreSite === 64 && e.isCompteSiteOuvert);
		// 	if (!cptOuvert) {
		// 		type = 'propriétaire';
		// 	}
		// }
		// if (this.site.entete.isLocataire && this.verifEtatCompte('Locataire')) {
		// 	const cptOuvert = this.site.entete.etatCompteSites.find((e) => e.genreSite === 128 && e.isCompteSiteOuvert);
		// 	if (!cptOuvert) {
		// 		type = 'locataire';
		// 	}
		// }
		// if (this.site.entete.isAuditeur && this.verifEtatCompte('Auditeur')) {
		// 	const cptOuvert = this.site.entete.etatCompteSites.find((e) => e.genreSite === 256 && e.isCompteSiteOuvert);
		// 	if (!cptOuvert) {
		// 		type = 'auditeur';
		// 	}
		// }

		// if (type) {
		// 	const errMsg = this.$translate.instant('SITES.ETAT_COMPTE_ACTIVATION_TYPE_FAILED', { type: type });
		// 	this.notification.error(errMsg);
		// 	return false;
		// }

		let firstScroll = true;
		if (!this.site.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}
			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('siteEnteteValidations');
		}

		if (this.site.caracteristiquesUtilisateur && !this.site.caracteristiquesUtilisateur.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiquesUtilisateur');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueUtilisateurOpen = true;
			validity = false;
			this.$scope.$broadcast('siteCaracteristiquesCommercialValidations');
		}

		if (this.site.caracteristiquesClient && !this.site.caracteristiquesClient.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiquesClient');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueClientOpen = true;
			validity = false;
			this.$scope.$broadcast('siteCaracteristiquesClientValidations');
		}

		if (this.site.caracteristiquesProducteur && !this.site.caracteristiquesProducteur.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiquesProducteur');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueProducteurOpen = true;
			validity = false;
			this.$scope.$broadcast('siteCaracteristiquesProducteurValidations');
		}

		if (this.site.caracteristiquesFournisseur && !this.site.caracteristiquesFournisseur.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiquesFournisseur');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueFournisseurOpen = true;
			validity = false;
			this.$scope.$broadcast('siteCaracteristiquesFournisseurValidations');
		}

		if (this.site.caracteristiquesTransporteur && !this.site.caracteristiquesTransporteur.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiquesTransporteur');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueTransporteurOpen = true;
			validity = false;
			this.$scope.$broadcast('siteCaracteristiquesTransporteurValidations');
		}

		if (this.site.caracteristiquesLaboratoire && !this.site.caracteristiquesLaboratoire.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiquesLaboratoire');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueLaboratoireOpen = true;
			validity = false;
			this.$scope.$broadcast('siteCaracteristiquesLaboratoireValidations');
		}

		if (this.site.caracteristiquesProprietaire && !this.site.caracteristiquesProprietaire.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiquesProprietaire');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueProprietaireOpen = true;
			validity = false;
			this.$scope.$broadcast('siteCaracteristiquesProprietaireValidations');
		}

		if (this.site.caracteristiquesLocataire && !this.site.caracteristiquesLocataire.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiquesLocataire');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueLocataireOpen = true;
			validity = false;
			this.$scope.$broadcast('siteCaracteristiquesLocataireValidations');
		}

		if (this.site.caracteristiquesAuditeur && !this.site.caracteristiquesAuditeur.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiquesAuditeur');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueAuditeurOpen = true;
			validity = false;
			this.$scope.$broadcast('siteCaracteristiquesAuditeurValidations');
		}

		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	onUpdateEntete(entete) {
		this.site.entete = entete;
	}

	onUpdateCaracteristiquesUtilisateur(caracteristiquesUtilisateur) {
		this.site.caracteristiquesUtilisateur = caracteristiquesUtilisateur;
	}

	onUpdateCaracteristiquesProducteur(caracteristiquesProducteur) {
		this.site.caracteristiquesProducteur = caracteristiquesProducteur;
	}

	onUpdateCaracteristiquesClient(caracteristiquesClient) {
		this.site.caracteristiquesClient = caracteristiquesClient;
	}

	onUpdateCaracteristiquesFournisseur(caracteristiquesFournisseur) {
		this.site.caracteristiquesFournisseur = caracteristiquesFournisseur;
	}

	onUpdateCaracteristiquesTransporteur(caracteristiquesTransporteur) {
		this.site.caracteristiquesTransporteur = caracteristiquesTransporteur;
	}

	onUpdateCaracteristiquesLaboratoire(caracteristiquesLaboratoire) {
		this.site.caracteristiquesLaboratoire = caracteristiquesLaboratoire;
	}

	onUpdateCaracteristiquesProprietaire(caracteristiquesProprietaire) {
		this.site.caracteristiquesProprietaire = caracteristiquesProprietaire;
	}

	onUpdateCaracteristiquesLocataire(caracteristiquesLocataire) {
		this.site.caracteristiquesLocataire = caracteristiquesLocataire;
	}

	onUpdateCaracteristiquesAuditeur(caracteristiquesAuditeur) {
		this.site.caracteristiquesAuditeur = caracteristiquesAuditeur;
	}

	onUpdateCertificationCe(certificationCe) {
		// this.site.certificationCe = certificationCe;
	}

	onUpdateNiveaux(niveaux) {
		this.site.niveaux = niveaux;
	}
	onUpdateEtatCompte(etatCompte) {
		this.site.etatCompte = etatCompte;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	onUpdateNiveaux(niveaux) {
		this.site.niveaux = niveaux;
	}

	openTarifs() {
		const _this = this;
		_this.source = this.site;
		_this.domaine = 'siteCommercial';
		if (this.genreSite == 'clients') {
			_this.domaine = 'client';
		}
		this.$uibModal
			.open({
				template: '<tarifs source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></tarifs>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.domaine = _this.domaine;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				function (result) {},
				function (reason) {}
			);
	}

	openGrilles() {
		const _this = this;
		_this.source = this.site;
		_this.domaine = 'siteCommercial';
		if (this.genreSite == 'clients') {
			_this.domaine = 'client';
		}
		if (this.genreSite == 'fournisseurs') {
			_this.domaine = 'fournisseur';
		}
		if (this.genreSite == 'transporteurs') {
			_this.domaine = 'transporteur';
		}
		this.$uibModal
			.open({
				template: '<grilles source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></grilles>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.domaine = _this.domaine;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	openImpressionPrix() {
		const _this = this;
		_this.source = this.site;

		this.$uibModal
			.open({
				template:
					'<plan-impression-prix domaine="planimprprix" client-id="$ctrl.clientId" modal-instance="$ctrl.uibModalInstance"></plan-impression-prix>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.uibModalInstance = $uibModalInstance;
						$ctrl.clientId = _this.site.id;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	verifEtatCompte(genreLibelle) {
		const appName = this.MassiaApplicationService.getApplication();
		if (
			(genreLibelle == 'Commercial' && appName == 'gestion') ||
			(genreLibelle == 'Client' && appName == 'gestion') ||
			(genreLibelle == 'Producteur' && appName == 'performance') ||
			(genreLibelle == 'Auditeur' && appName == 'qse') ||
			(genreLibelle == 'Laboratoire' && appName == 'laboratoire')
		) {
			return false;
		}
		return true;
	}

	verifAffichageEtatCompte() {
		const appName = this.MassiaApplicationService.getApplication();
		if (
			(this.site.genreLibelle == 'Commercial' && appName == 'gestion') ||
			(this.site.genreLibelle == 'Producteur' && appName == 'performance') ||
			(this.site.genreLibelle == 'Auditeur' && appName == 'qse') ||
			(this.site.genreLibelle == 'Laboratoire' && appName == 'laboratoire')
		) {
			return false;
		}
		return true;
	}

	async setDefaultEtatCompte(id) {
		try {
			await this.SitesService.setDefaultEtatCompte(id, this.application);
		} catch (err) {
			this.notification.error('SITES.ETAT_COMPTE_AUTO_FAILED');
		}
	}

	formatDatesCertificat() {
		this.certificats.forEach((certificat) => {
			certificat.dateDebut = this.moment.utc(certificat.dateDebut, this.dateFormat);
			certificat.dateFin = this.moment.utc(certificat.dateFin, this.dateFormat);
		});
	}

	showMap() {
		const url = `https://www.openstreetmap.org/?mlat=${this.site.latitude}&mlon=${this.site.longitude}&zoom=14&layers=M`;
		window.open(url, 'carte');
	}
}
