ChantiersController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'ChantiersService',
	'notification',
	'$transitions'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function ChantiersController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	ChantiersService,
	notification,
	$transitions
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedChantierId = undefined;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.genres = {
		provenance: 'provenances',
		chantier: 'chantiers'
	};
	vm.chantiers = [];
	vm.isChantier = $state.$current.parent?.name === 'chantiers';
	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectChantier = selectChantier;
	vm.isChantierSelected = isChantierSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadChantiers = loadChantiers;
	vm.deleteChantier = deleteChantier;
	vm.setSelected = setSelected;
	vm.exporter = exporter;
	vm.genreFilter = genreFilter;
	vm.licenceGest = __configuration.licenceGest;

	function init() {
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "chantiers.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		const isGenreOk = vm.state.$current.parent?.name === vm.genres.provenance || vm.state.$current.parent?.name === vm.genres.chantier;
		vm.genre = isGenreOk ? vm.state.$current.parent?.name : vm.genres.chantier;
		// vm.isChantier = !(vm.state.$current.parent?.name === vm.genres.provenance);
		//quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	init();

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(chantier) {
		if (chantier && chantier.id) {
			selectedChantierId = $stateParams.id;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			selectedChantierId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return (
			$state && $state.current && $state.current.name && $state.current.name === `${vm.genre}.list.detail` && $stateParams && $stateParams.id
		);
	}

	function selectChantier(chantier) {
		if (chantier && chantier.id) {
			//si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedChantierId, et charger la vue détail pour le chantier sélectionné
			if (selectedChantierId !== chantier.id) {
				showDetail(chantier.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				closeDetail();
			}
		}
	}

	function isChantierSelected(chantier) {
		return chantier && chantier.id && selectedChantierId === chantier.id;
	}

	function showDetail(chantierId) {
		selectedChantierId = chantierId;
		$state.go(`${vm.genre}.list.detail`, { id: chantierId });
	}

	function closeDetail() {
		selectedChantierId = undefined;
		$state.go(`${vm.genre}.list`);
	}

	async function loadChantiers(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "chantiers.list", et on ferme la vue détail d'un chantier
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== `${vm.genre}.list`) {
			$state.go(`${vm.genre}.list`);
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.chantiers = [];

		try {
			filters.push(genreFilter(vm.genre, vm.genres));

			const data = await ChantiersService.getChantiers(filters, sorts, pagination);

			vm.chantiers = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
			console.log(vm.chantiers);
		}
	}

	async function deleteChantier(chantier) {
		if (chantier && chantier.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant(vm.ischantier ? 'CHANTIERS.DELETE.TITLE' : 'CHANTIERS.DELETE_PROV.TITLE', { code: chantier.libelle }),
				modalMsg: $translate.instant(vm.ischantier ? 'CHANTIERS.DELETE.MESSAGE' : 'CHANTIERS.DELETE_PROV.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await ChantiersService.deleteChantierById(chantier.id);
					notification.success($translate.instant(vm.ischantier ? 'CHANTIERS.DELETE.SUCCESS' : 'CHANTIERS.DELETE_PROV.SUCCESS'));

					selectedChantierId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.params.id) {
						vm.state.go(`${vm.genre}.list`);
					}

					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

					loadChantiers();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = vm.isChantier ? 'Liste_chantiers' : 'Liste_provenances';
			let resultat = undefined;
			try {
				filters.push(genreFilter(vm.genre, vm.genres));

				resultat = await ChantiersService.exportListeChantiers(modeImpression, vm.isChantier, filters, sorts);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error(vm.isChantier ? 'CHANTIERS' : 'PROVENANCES' + msgException);

				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	// Filtre pour avoir les chantiers pour les comptes ouvert de fournisseur(8) ou client(2)
	function genreFilter(genre, genresEnum) {
		if (genre === genresEnum.provenance) {
			return {
				criterion: 'SiteGenre',
				value: 8,
				type: 'ge'
			};
		}
		return {
			criterion: 'SiteGenre',
			value: 2,
			type: 'ge'
		};
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
