export default class DapValidationModalController {
    /* @ngInject */
    constructor($scope, $stateParams, DAPsService, notification, ModalService, $uibModal, moment, $translate) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.DAPsService = DAPsService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.$translate = $translate
        this.moment = moment;
        this.motifRefus = null
        this.dateValidate = null
        this.isRefus = false;
        this.fullName = "";
        this.statut = 0;
    }

    async $onInit()
    {
        console.log(this.resolve.dap);
        this.isRefus = this.resolve.isRefus;
        this.statut = (this.isRefus) ? 4 : 3;
        const userData = JSON.parse(localStorage.getItem("massia.MassiaPersistsUserData"));
        this.personneValidateId = userData.id;
        this.fullName = `${userData.nom} ${userData.prenom}`;
        console.log("🚀 ~ DapValidationModalController ~ userData:", userData)
        
    }

    getControles()
    {
        let res = true;
        if(!this.motifRefus && this.isRefus)
        {
            res = false;
        }

        if(!this.dateValidate)
        {
            res = false;
        }

        if(!res) this.notification.error('Vous devez remplir tous le formulaire');
        return res;
    }

    async ok()
    {

        if(!this.getControles()) return;

        const data = {
            id: this.resolve.dap.id,
            personneValidationId: this.personneValidateId,
            dateValidation: this.dateValidate,
            statut: this.statut,
            motifRefus: this.motifRefus
        }
        console.log("🚀 ~ DapValidationModalController ~ data:", data)

        let a = await this.DAPsService.UpdateDapAttente(data);

        this.notification.success(this.$translate.instant('DAPS.ATTENTE.REJECT_TOAST'));
        this.resolve.dap.statut = 4;
        this.cancel();
    }

    cancel(){
        this.modalInstance.dismiss();
    }
}