import L from 'leaflet/dist/leaflet';
import 'leaflet-easyprint';
import 'leaflet-measure/dist/leaflet-measure.fr';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.min';

export default class DapAttenteFormController {
    /* @ngInject */
    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        DAPsCommunicationService,
        DAPsService,
        DocumentsService,
        notification,
        $location,
        $anchorScroll,
        $translate,
        $uibModal,
        ModalService
    ){
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.DAPsCommunicationService = DAPsCommunicationService;
        this.DAPsService = DAPsService;
        this.DocumentsService = DocumentsService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$translate = $translate;
        this.$uibModal = $uibModal;
        this.ModalService = ModalService;
        this.currDap = null;
        this.loading = false;
        this.isOpenForm = true;
        this.nomenclatures = [];
        this.map = {};
    }
    
    async $onInit(){
      this.isOpen = true;
      this.currDap = await this.DAPsService.GetDapAttenteById(this.$stateParams.id);
      console.log("🚀 ~ DapAttenteFormController ~ $onInit ~ this.currDap:", this.currDap)

      if(this.currDap.statut === 1)
      {
        this.currDap.statut = 2;
        this.DAPsService.UpdateDapAttenteAsSeen(this.currDap.id);
      }

      this.nomenclatures = this.currDap.dechet.map(d => 
        {
            const nomenclature = this.currDap.nomenclatures.find(n => n.libelle === d.libelle)
            if(!!nomenclature)
            {
                return {
                    libelle: nomenclature.libelle,
                    code: nomenclature.code,
                    qte: d.quantiteEstimee
                }
            }
            else
            {
                return {
                    libelle: nomenclature.libelle,
                    code: nomenclature.code,
                    qte: 0
                }
            }
        });

        this.initCarte();
    }

    async validate()
    {
        if(this.currDap.haveCreation)
        {
            this.$state.go('daps.validate', {
                id: this.$stateParams.id
            });
        }
        else
        {
            try {
				let r = await this.DAPsService.createDAPfromAttente(this.currDap);
				this.notification.success(this.$translate.instant('DAPS.ATTENTE.VALIDATE_TOAST'));
				this.DAPsService.deleteDAPAttente(this.currDap.id);
				this.$state.go('daps.edit', {id:r});
			} catch (error) {
				console.log(error)
			}
        }
    }

    async reject(){
        try {
			const modalInstance = this.$uibModal.open({
				animation: true,
				size: 's',
				component: 'dapValidationModal',
				resolve: {
					dap: () => this.currDap,
					isRefus: () => true
				},
                controllerAs: '$ctrl',
			});
        } catch (error) {
            this.notification.error(this.$translate.instant('DAPS.ATTENTE.REJ_ERROR'));
			console.log(error)
        }
    }

    async restore()
    {
        try {
            await this.DAPsService.UpdateDapAttenteAsSeen(this.$stateParams.id);
            this.notification.success(this.$translate.instant('DAPS.ATTENTE.RESTORE_TOAST'));
            this.currDap.statut = 2;
        } catch (error) {
            this.notification.success(error.error);
        }
    }

    annuler()
    {
        this.$state.go('daps.list');
    }

    async initCarte() {
		const _this = this;
		this.zoom = 5;
		this.longitude = 3.5;
		this.latitude = 45.5;
		if (this.currDap.chantier.longitude) {
			this.longitude =this.currDap.chantier.longitude;
		}
		if (this.currDap.chantier.latitude) {
			this.latitude = this.currDap.chantier.latitude;
		}
		if (this.currDap.chantier.longitude && this.currDap.chantier.latitude) {
			this.zoom = 15;
		}

		$('#map', async () => {
			this.map = L.map('map').setView([this.latitude, this.longitude], this.zoom); //par défaut

			this.map.scrollWheelZoom.disable();

			/*this.map.on('mapReady', function (map) {
                setTimeout(() => {
                    map.invalidateSize();
                }, 0);
            }, this);*/

			//Couches
			const Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				//z:niveau de zoom, x et y coordonnées du pavé
				attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				maxZoom: 20 //zoom maximum de la carte
			});
			Mapnik.addTo(this.map);

			//ajout du plan topo
			const topo = await this.planTopo();

			this.blueMarker = L.icon({
				iconUrl: 'assets/img/pin_GO.png',
				iconSize: [64, 64], // size of the icon
				iconAnchor: [8, 55] // point of the icon which will correspond to marker's location
			});
			this.marqueurSite = L.marker([this.latitude, this.longitude], {
				icon: this.blueMarker,
				draggable: 'true' /*, class:'leaflet-marker-pane'*/
			});
			this.marqueurSite.on(
				'dragend',
				function (event) {
					const marker = event.target;
					const position = marker.getLatLng();
					this.changePositionMarqueur(position, true);
					this.map.setView(position, 15);
				},
				this
			);
			this.marqueurSite.addTo(this.map);

			this.map.on('click', this.onMapClick, this);

			this.map.panTo(new L.LatLng(this.latitude, this.longitude));

			//plein écran
			const msgVoirPleinEcran = _this.$translate.instant('SITES.VOIR_PLEIN_ECRAN');
			const msgQuitterPleinEcran = _this.$translate.instant('SITES.QUITTER_PLEIN_ECRAN');
			this.map.addControl(
				new L.Control.Fullscreen({
					title: {
						false: msgVoirPleinEcran,
						true: msgQuitterPleinEcran
					}
				})
			);

			//impression
			const pngFileName = "dap-attente-chantier-coord";
			const ep = L.easyPrint({
				title: _this.$translate.instant('EXPORTER'),
				position: 'topleft',
				sizeModes: ['Current', 'A4Portrait', 'A4Landscape'],
				exportOnly: true,
				filename: pngFileName,
				tileLayer: Mapnik,
				hideControlContainer: true
			});
			ep.addTo(this.map);

			//panel de gestion des couches
			const OSMFr = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
				//z:niveau de zoom, x et y coordonnées du pavé
				attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				maxZoom: 20 //zoom maximum de la carte
			});

			const baseLayer = {
				'OpenStreetMap Org': Mapnik,
				'OpenStreetMap France': OSMFr
			};
			const overlaysLayers = {};
			if (topo) {
				overlaysLayers['Plan topographique'] = topo;
			}

			L.control.layers(baseLayer, overlaysLayers, { collapsed: true }).addTo(this.map);
		});
	}

	onMapClick(event) {
		this.changeCoord(event);
		this.$scope.$broadcast('myEvent', []);
	}

	changeCoord(event) {
		this.changePositionMarqueur(event.latlng, true);
		this.map.setView(event.latlng, 15);
	}

	async planTopo() {
		return undefined;
	}

	changePositionMarqueur(evtLatLgn, diffuseEntete) {
		this.marqueurSite.setLatLng(evtLatLgn).update();
		this.site.latitude = evtLatLgn.lat;
		this.site.longitude = evtLatLgn.lng;
		this.afficheCarte();
	}

	afficheCarte() {
		//est-ouest:latitude, nord-sud:longitude
		if (this.map && (this.longitude != this.site.longitude || this.latitude != this.site.latitude)) {
			//let zoom = this.zoom;
			let longitude = this.site.longitude;
			let latitude = this.site.latitude;
			if (!longitude || !latitude) {
				longitude = 3.5;
				latitude = 45.5;
				//zoom=5;
			}
			if (this.longitude != this.site.longitude || this.latitude != this.site.latitude) {
				this.longitude = longitude;
				this.latitude = latitude;
				this.map.panTo(new L.LatLng(this.latitude, this.longitude));
				//var latlng = {lat: this.latitude ,lng: this.longitude};
			}
		}
	}
}