import ChantierEntete from './chantier.entete.model';
import Contact from '../../../../../common/personnes/components/mini-form/contact/mini.form.contact.model';
import * as angular from 'angular';

export default class ChantierFormEnteteController {
	/* @ngInject */

	contacts = [];
	contactsSelected = [];
	newContact = new Contact();
	firstLaunch1 = true;
	firstLaunch2 = true;

	constructor(
		$scope,
		$stateParams,
		$translate,
		ChantiersCommunicationService,
		ChantiersService,
		ChantiersTypesService,
		notification,
		SitesService,
		MassiaApplicationService
	) {
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.$translate = $translate;
		this.ChantiersCommunicationService = ChantiersCommunicationService;
		this.ChantiersService = ChantiersService;
		this.ChantiersTypesService = ChantiersTypesService;
		this.notification = notification;
		this.SitesService = SitesService;
		this.MassiaApplicationService = MassiaApplicationService;
	}

	async $onInit() {
		this.isChantier = this.$stateParams.genre === 'chantiers';

		this.unregister = [];
		this.loading = false;
		this.codeLoading = false;
		if (this.$stateParams && this.$stateParams.id) {
			this.isEditMode = true;
			this.setChantierAsTypeAhead();
		} else {
			this.isEditMode = false;
		}

		this.hasTypes = false;
		this.chantier = this.chantier || {};

		this.entete = new ChantierEntete(this.chantier.entete);
		try {
			this.entete.estCodeAuto = await this.ChantiersService.estCodeAuto();
		} catch (ex) {
			this.notification.error(ex.data);
			this.annuler();
		}

		this.types = this.types || [];

		// on sauvegarde la fonction de désabonnement pour l'invoquer au destroy
		// il est important de noter la façon de s'abonner :
		// on utilise les arrow functions, () => {},
		// elles permettent de sauvegarder l'état du 'this'
		// et d'éviter la perte du contexte lors de l'appel de la fonction depuis un autre controller
		this.unregisterReinit = this.ChantiersCommunicationService.registerReinit(() => this.initForm());

		this.isClientDisabled = false;
		if (this.$stateParams.clientId) {
			this.isClientDisabled = true;
		} else {
			if (this.entete.isClientDisabled) {
				this.isClientDisabled = true;
			}
		}

		await this.getNiveauVisibilite();
		await this.clientChange();

		this.contacts.forEach(function (contact) {
			if (contact.id == this.chantier.entete.idContact) {
				contact.selected = true;
			}
		}, this);

		if (this.chantier.entete.idContact) {
			this.contactsSelected.push({ id: this.chantier.entete.idContact, libelle: this.chantier.entete.nomContact });
		}

		if (this.chantier.entete.idLieu) {
			this.setChantierAsTypeAhead();

			// this.lieu = this.chantier.entete.idLieu;
		}

		// Pour la gestion des deux checkbox "Du Batiment"
		this.setHeritageAndChantierDuBatiment();

		//Met a jour lors du changement de siteclient
		this.unregister.push(
			this.$scope.$watch(
				() => this.entete.objetSiteClient,
				async () => {
					if (!this.firstLaunch1 === false) {
						this.firstLaunch1 = false;
					} else {
						this.entete.isClientDuBatiment = false;
						const siteClient = this.chantier.entete.objetSiteClient;
						if (siteClient && siteClient.id != null && this.entete.isHeritageFromClient) {
							this.entete.isDuBatiment = siteClient.isDuBatiment;
							this.entete.isClientDuBatiment = siteClient.isDuBatiment;
						}
					}
				}
			)
		);

		this.unregister.push(
			this.$scope.$watch(
				() => this.entete.isHeritageFromClient,
				async () => {
					if (!this.firstLaunch2 === false) {
						this.firstLaunch2 = false;
					} else {
						this.changeEnteteChantierBatiment();
					}
				}
			)
		);

		if (this.chantier.entete.objetSociete.id === 0) {
			this.entete.nomSociete = null;
			this.entete.objetSociete = null;
		}

		console.log(
			'🚀 ~ file: chantier.form.entete.controller.js:133 ~ ChantierFormEnteteController ~ $onInit ~ this.chantier.entete:',
			this.chantier.entete
		);
	}

	async getNiveauVisibilite() {
		if (this.entete.typeId) {
			this.entete.niveauVisibilite = await this.ChantiersTypesService.GetNiveauVisibilite(this.entete.typeId);
		}
	}

	// SL si un changement est fait dans le parent,
	// on met a jour les donnees dans l enfant
	$onChanges(objChanges) {
		if (objChanges.chantier) {
			this.getPayss();
			this.getTypes();
			//this.getLieux();
		}
	}

	// SL si angular voit que les donnees originales sont differentes des donnees dupliquees
	// le parent recupere les donnees de l enfant
	$doCheck() {
		if (!angular.equals(this.entete, this.chantier.entete)) {
			this.onUpdate({
				entete: angular.copy(this.entete)
			});
		}
	}

	$onDestroy() {
		this.unregisterReinit();

		for (let i = 0; i < this.unregister?.length; i++) {
			this.unregister[i]();
		}
	}
	// debug() {
	// 	console.log('👻: ChantierFormEnteteController -> debug -> ', this);
	// }

	async getTypes() {
		try {
			this.types = await this.ChantiersTypesService.getAll();
			this.hasTypes = this.types?.length > 0;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
		}
	}

	async getPayss() {
		try {
			this.payss = await this.ChantiersService.getAllPayss();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
		}
	}

	async getSites() {
		try {
			// SL appel du get permettant de remplir les combobox
			this.sites = await this.SitesService.getSitesHeaders();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
		}
	}

	async getLieux(valeur) {
		const lieux = await this.ChantiersService.getLieux(valeur, this.chantier.entete.idLieu);
		return lieux;
	}

	async getNomSiteClients(valeur) {
		console.log('🚀 ~ file: chantier.form.entete.controller.js:205 ~ ChantierFormEnteteController ~ getNomSiteClients ~ valeur:', valeur);
		try {
			const res = await this.ChantiersService.getNomSiteClients(valeur, this.isChantier);
			console.log('🚀 ~ file: chantier.form.entete.controller.js:208 ~ ChantierFormEnteteController ~ getNomSiteClients ~ res:', res);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getSociete(valeur) {
		try {
			const res = await this.ChantiersService.getSocietes(valeur);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async checkCodeUnicity(code) {
		if (!code) {
			this.chantier.entete.codeExists = null;
			return;
		}
		this.startCodeLoading();
		try {
			if (code.match(/^[a-zA-Z0-9_|]*$/)) {
				this.entete.codeExists = await this.ChantiersService.codeExists(code);
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopCodeLoading();
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}

	setTypeAheadAs(val) {
		if (!this.chantier) {
			this.chantier = {};
		}
		switch (val) {
			case 'siteClient':
				this.chantier.entete.idSite = this.siteClient.id;
				this.chantier.entete.nomSite = this.siteClient.libelle;
				this.chantier.entete.codeSite = this.siteClient.code;
				this.getChantiers();
				break;
			case 'lieu':
				this.entete.idLieu = this.lieu.id;
				this.entete.nomLieu = this.lieu.libelle;
				this.entete.codeLieu = this.lieu.code;
				break;
		}
	}

	setChantierAsTypeAhead() {
		this.chantier.entete.objetSiteClient = {
			id: this.chantier.entete.idSite,
			libelle: this.chantier.entete.nomSite,
			code: this.chantier.entete.codeSite
		};
		this.chantier.entete.objetSociete = {
			id: this.chantier.entete.idSociete,
			libelle: this.chantier.entete.nomSociete,
			code: this.chantier.entete.codeSociete
		};
		this.lieu = {
			id: this.chantier.entete.idLieu,
			code: this.chantier.entete.codeLieu,
			libelle: this.chantier.entete.nomLieu,
			codeEtLib: this.chantier.entete.idLieu ? this.chantier.entete.nomLieu + ' [' + this.chantier.entete.codeLieu + ']' : ''
		};
	}

	setHeritageAndChantierDuBatiment() {
		const isCreation = typeof this.$stateParams?.id == 'undefined';
		if (isCreation) {
			this.entete.isHeritageFromClient = true;
		}
	}

	async changeEnteteChantierBatiment() {
		if (this.entete.isHeritageFromClient) {
			this.entete.isDuBatiment = this.entete.isClientDuBatiment;
		}
	}

	videLieu() {
		console.log(this.isClientDisabled);
		if (!this.isClientDisabled) {
			this.lieu = {
				id: undefined,
				code: undefined,
				libelle: undefined,
				codeEtLib: ''
			};
			this.entete.idLieu = undefined;
			this.entete.nomLieu = undefined;
			this.entete.codeLieu = undefined;
		}
	}

	async clientChange() {
		this.contactsSelected = [];
		if (this.chantier.entete.objetSiteClient && this.chantier.entete.objetSiteClient.id != null) {
			this.contacts = await this.ChantiersService.getContactsBySite(this.chantier.entete.objetSiteClient.id);
		}
	}

	async societeChange() {}

	contactChange() {
		if (this.contactsSelected?.length == 1) {
			this.entete.idContact = this.contactsSelected[0].id;
		} else {
			this.entete.idContact = null;
		}
	}

	selectNewContact() {
		this.contacts.forEach(function (contact) {
			contact.selected = false;
		});

		if (this.newContact.id) {
			this.contacts.push({
				id: this.newContact.id,
				libelle: this.newContact.prenom + ' ' + this.newContact.nom,
				selected: true
			});
		}

		this.contactChange();
	}
}
